import 'hammerjs';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { loadApiConfig } from '@paperclip/core/app.config';
import { environment } from '@paperclip/environments/environment';

import { AppModule } from './app/app.module';

loadApiConfig()
  .then(() => {
    if (environment.production) {
      enableProdMode();
    }

    platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .then(() => {
        if ('serviceWorker' in navigator && environment.production) {
          navigator.serviceWorker.register('ngsw-worker.js');
        }
      })
      .catch((err) => console.log(err));
  })
  .catch((err) => console.log(err));
