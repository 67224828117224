import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationMessageService } from '@paperclip/core/confirmation-message.service';
import { CoreService } from '@paperclip/core/core.service';
import { PcApiResponse } from '@paperclip/models/misc/pc-api-response';
import { Order } from '@paperclip/models/order';

import { ReviewTradeService } from './review-trade.service';

@Component({
  selector: 'pc-review-trade-modal',
  templateUrl: './review-trade-modal.component.html',
  styleUrls: ['./review-trade-modal.component.scss']
})
export class ReviewTradeModalComponent {
  reviewRating = 5;
  hoverStarRating = 0;
  activeRequest: boolean;
  reviewForm: UntypedFormGroup = this.formBuilder.group({
    reviewRating: [5, Validators.required],
    reviewText: ['', Validators.maxLength(500)]
  });

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ReviewTradeModalComponent>,
    // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/ban-types
    @Inject(MAT_DIALOG_DATA) public modalData: { order: Order; page: 'selling' | 'buying'; confirmed: () => {}; modalClosed: () => {} },
    private reviewTradeService: ReviewTradeService,
    private confirmationMessageService: ConfirmationMessageService,
    private coreService: CoreService
  ) {}

  setReviewRating(rating: number) {
    this.reviewRating = rating;
    this.reviewForm.patchValue({ reviewRating: rating });
  }

  mouseoverStarRating(rating: number) {
    this.hoverStarRating = rating;
  }

  mouseleaveStarRating() {
    this.hoverStarRating = 0;
  }

  addReview(reviewForm: any) {
    this.activeRequest = true;
    this.reviewTradeService
      .addReview(
        this.modalData.order.id,
        this.modalData.page === 'buying'
          ? this.modalData.order.seller.id
          : this.modalData.order.buyer.id,
        reviewForm.reviewRating,
        reviewForm.reviewText
      )
      .subscribe(
        ({ code, message }: PcApiResponse) => {
          this.activeRequest = false;
          if (code === 1) {
            this.modalData.confirmed();
            this.confirmationMessageService.showConfirmationMessage('reviewed-user');
            this.closeModal();
          } else {
            this.coreService.handleError({ code, message });
          }
        },
        () => {
          this.activeRequest = false;
          this.coreService.handleError({});
        }
      );
  }

  closeModal() {
    this.modalData.modalClosed();
    this.dialogRef.close();
  }
}
