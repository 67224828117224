import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorLoggingService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  logClientError(error: Error): Observable<any> {
    const errorLog = {
      error: error.name,
      errorMessage: error.message,
      errorStack: error.stack,
      userId: this.authService.isLoggedIn() ? this.authService.getAuthedUser().detail?.id : 'guestUser'
    };

    const formData: FormData = new FormData();
    formData.append('message', JSON.stringify(errorLog));
    return this.http.post<any>(`logger/log`, formData);
  }

  logServerError(error: HttpErrorResponse): Observable<any> {
    const errorLog = {
      api: error.url,
      statusCode: error.status,
      statusText: error.statusText,
      message: error.message,
      userId: this.authService.isLoggedIn() ? this.authService.getAuthedUser().detail?.id : 'guestUser'
    };

    const formData: FormData = new FormData();
    formData.append('message', JSON.stringify(errorLog));
    return this.http.post<any>(`logger/log`, formData);
  }
}
