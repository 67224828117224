import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from '@paperclip/environments/environment';

import { ErrorLoggingService } from './error-logging.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private errorLoggingService: ErrorLoggingService) {}

  handleError(error: Error) {
    console.error('GlobalErrorHandler', error);

    if (error instanceof HttpErrorResponse) {
      this.errorLoggingService.logServerError(error);
    } else {
      this.errorLoggingService.logClientError(error);
    }
  }
}
