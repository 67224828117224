import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@paperclip/core/auth/auth.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TradingService {
  loggedInUserId: string = this.authService.getAuthedUser().detail?.id || '';
  constructor(private http: HttpClient, private authService: AuthService) {}

  deleteTrade(tradeId: string): Observable<any> {
    return this.http.post(`offers/retract`, { offerId: tradeId, userId: this.loggedInUserId });
  }

  markAsDispatched(orderId: string): Observable<any> {
    return this.http.post(`orders/${orderId}/dispatch`, {});
  }

  markAsReceived(orderId: string): Observable<any> {
    return this.http.post(`orders/${orderId}/collect`, {});
  }

  disputeOrder(orderId: string, disputeText: string): Observable<any> {
    return this.http.post(`orders/${orderId}/dispute`, { text: disputeText });
  }
}
