import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppConfig } from '@paperclip/core/app.config';
import { AuthService } from '@paperclip/core/auth/auth.service';
import { ConfirmationMessageService } from '@paperclip/core/confirmation-message.service';
import { CoreService } from '@paperclip/core/core.service';
import { UpdatesService } from '@paperclip/core/updates-service.service';
import { WindowResizeService } from '@paperclip/core/window-resize.service';
import { ModalService } from '@paperclip/modals/modal.service';
import {
  DisputeSubmittedModalComponent,
  DisputeTradeModalComponent,
  MarkDispatchedModalComponent,
  MarkReceivedModalComponent,
  ReviewTradeModalComponent,
  SocialInventoryOneTimeModalComponent
} from '@paperclip/modals/trading/trading-modals';
import { OrderDetailLayout } from '@paperclip/models/component/order-detail-Layout';
import { Order, OrderView } from '@paperclip/models/order';
import { AuthedUser } from '@paperclip/models/user/AuthedUser';
import { Subscription } from 'rxjs';

@Component({
  selector: 'pc-trading-order-card',
  templateUrl: './trading-order-card.component.html',
  styleUrls: ['./trading-order-card.component.scss']
})
export class TradingOrderCardComponent implements OnInit, OnDestroy, OnChanges {
   
  @Input() order: Order;  
  windowResizeSubscription: Subscription;
  windowSize: string;
  showEditItemButton: boolean;
  loggedInUserId: string = this.authService.getAuthedUser().detail?.id || '';
  authedUser: AuthedUser = this.authService.getAuthedUser();
  paymentTransactionsEnabled: boolean = AppConfig.app.features.payments.enabled;
  paymentTransactionsEnabledSubscription: Subscription;
  @Input() showHeaderAndItem = true;
  @Input() orderDetailLayout: OrderDetailLayout;
  @Input() showLoading = false;
  @Input() tradingPage: 'buying' | 'selling';
  menu: 'selling-menu' | 'buying-menu';
  orderView: OrderView;

  constructor(
    private router: Router,
    private authService: AuthService,
    private windowResizeService: WindowResizeService,
    private modalService: ModalService,
    private translateService: TranslateService,
    private updateService: UpdatesService,
    private coreService: CoreService,
    private confirmationMessageService: ConfirmationMessageService
  ) {
    this.windowSize = window.innerWidth < 750 ? 'mobile' : window.innerWidth < 1200 ? 'tablet' : 'desktop';
    this.windowResizeSubscription = this.windowResizeService.windowWidthDidChange().subscribe((width: number) => {
      this.windowSize = width < 750 ? 'mobile' : width < 1200 ? 'tablet' : 'desktop';
      if (this.order) {
        this.controlOrderDetailLayout();
      }
    });

    this.paymentTransactionsEnabledSubscription = this.coreService
      .getPaymentTransactionsEnabled()
      .subscribe((enabled: boolean) => {
        this.paymentTransactionsEnabled = enabled;
      });

  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.order) {
      this.orderView = new OrderView(this.order, this.tradingPage, this.authService.getAuthedUser().detail.id)
    }
  }

  ngOnInit() {
    this.paymentTransactionsEnabled = AppConfig.app.features.payments.enabled;
    this.controlOrderDetailLayout();
    this.menu = this.tradingPage === 'buying' ? 'buying-menu' : 'selling-menu'
  }

  ngOnDestroy(): void {
    this.windowResizeSubscription.unsubscribe();
    this.paymentTransactionsEnabledSubscription.unsubscribe();
  }

  private controlOrderDetailLayout() {
    // don't change the layout if showHeaderAndItem is set to false because it
    // is then controlled by item-offers component
    const showOrderStatus = true;
    if (this.showHeaderAndItem) {
      this.orderDetailLayout = {
        headerTitle: this.windowSize === 'desktop',
        showMenu: this.windowSize === 'desktop',
        showOrderStatus: this.windowSize !== 'desktop' && showOrderStatus,
        showOrderFooter: this.windowSize !== 'desktop'
      };
    }
  }

  public editItem() {
    this.router.navigate(['/edit-item', this.order?.item.detail.id]);
  }

  private refreshOrder(apiOrder: Order) {
    this.order = apiOrder;
    this.updateService.sendUpdateData({ action: 'refresh-trading-stats' });
  }

  public getButtonLink(action: string) {
    if (this.order.isSuspended()) {
      return;
    }

    switch (action) {
      case 'message-seller':
        return ['/messages', this.order?.seller?.id];

      case 'message-buyer':
        return ['/messages', this.order?.buyer?.id];

      case 'send-reminder':
        return [
          '/messages',
          this.tradingPage === 'buying' ? this.order?.seller?.id : this.order?.buyer?.id
        ];
    }
  }

  public performOrderAction(action: string) {
    if (this.order.isSuspended()) {
      this.confirmationMessageService.showConfirmationMessage('trade-suspended');
      return;
    }

    switch (action) {

      case 'buy-now':
      case 'resend-payment':
        this.buyNow();
        break;

      case 'mark-dispatched':
        this.markAsDispatched();
        break;

      case 'mark-delivered':
      case 'mark-collected':
        this.markAsReceived(action);
        break;

      case 'leave-review':
        this.reviewTrade();
        break;

      case 'dispute-trade':
        this.disputeTrade();
        break;
    }
  }
  
  private buyNow() {
    if (this.paymentTransactionsEnabled) {
      this.goToItemCheckout();
    } else {
      this.confirmationMessageService.showConfirmationMessage('payments-disabled');
    }
  }

  private goToItemCheckout() {
    this.router.navigate(['/checkout'], {
      queryParams: { item: this.order?.item.detail.id, orderId: this.order?.id }
    });
  }

  private markAsDispatched() {
    this.modalService.open(MarkDispatchedModalComponent, {
      data: {
        orderId: this.order?.id,
        confirmed: (order: Order) => {
          this.refreshOrder(order);
        }
      }
    });
  }

  private markAsReceived(action: string) {
    this.modalService.open(MarkReceivedModalComponent, {
      disableClose: true,
      data: {
        order: this.order,
        action: action,
        confirmed: (order: Order) => {
          this.refreshOrder(order);
        },
        leaveReview: (socialInventoryModalSeen: boolean) => this.reviewTrade(socialInventoryModalSeen),
        modalClosed: (socialInventoryModalSeen: boolean) =>
          this.showSocialInventoryOneTimeModal(socialInventoryModalSeen)
      }
    });
  }

  private reviewTrade(socialInventoryModalSeen = false) {
    this.modalService.open(ReviewTradeModalComponent, {
      type: 'centered',
      disableClose: true,
      data: {
        order: this.order,
        confirmed: () => {          
          this.order.isReviewed = true
          this.refreshOrder(this.order)
          //TODO review if this is reflected
          // this.order.detail.stateConfig = {
          //   ...this.order?.detail.stateConfig,
          //   buttons: [
          //     {
          //       action: 'reviewed',
          //       class: 'btn-secondary',
          //       link: false,
          //       disabled: true
          //     }
          //   ]
          // };
        },
        modalClosed: () => this.showSocialInventoryOneTimeModal(socialInventoryModalSeen)
      }
    });
  }

  private showSocialInventoryOneTimeModal(socialInventoryModalSeen: boolean) {
    if (!socialInventoryModalSeen) {
      this.modalService.open(SocialInventoryOneTimeModalComponent, {
        extraClass: 'generic--left',
        disableClose: true,
        // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/ban-types
        data: { closed: () => {} }
      });
    }
  }

  public disputeTrade() {
    if (this.order.isSuspended()) {
      this.confirmationMessageService.showConfirmationMessage('trade-suspended');
      return;
    }

    this.modalService.open(DisputeTradeModalComponent, {
      type: 'centered',
      data: {
        order: this.order,
        confirmed: (apiOrder: Order) => {
          this.modalService.open(DisputeSubmittedModalComponent, {});
          this.refreshOrder(apiOrder);
        }
      }
    });
  }
}
