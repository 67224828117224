import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@paperclip/core/auth/auth.service';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReviewTradeService {
  authedUserId: string = this.authService.getAuthedUser().detail?.id || '';

  private trade = new Subject<any>();

  updateTradeData(trade: any) {
    this.trade.next(trade);
  }

  getUpdatedTrade(): Observable<any> {
    return this.trade.asObservable();
  }
  constructor(private http: HttpClient, private authService: AuthService) {}

  addReview(offerId: string, userId: string, reviewRating: number, reviewText: string): Observable<any> {
    return this.http.post('users/review', {
      offerId: offerId,
      currentUserId: this.authedUserId,
      otherUserId: userId,
      rating: reviewRating,
      review: reviewText
    });
  }
}
